<template>
    <div>
        {{$user.get().role}}
        hello dashbourd
    </div>
</template>
<script>
// alert(this.$user.get().role);
export default {
    
}
</script>